@import "../milligram/Color";
@import "../milligram/Variables";

.banner {
  background: url('/images/content/background-subbanner.png') no-repeat bottom center;
  background-size: cover;
  min-height: 341px;
  padding: 155px 0 62px;
  position: relative;
  .wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    max-width: 892px;
  }
  h3 {
    color: $color-primary;
    font-weight: 600;
    line-height: 42px;
    margin-bottom: 26px;
    text-align: center;
    text-transform: uppercase;
  }
  &__search { 
    background: $color-initial;
    border: 1px solid #DBDBDB;
    border-radius: 80px;
    height: 56px;
    position: relative;
    width: 700px;
    input[type="text"] {
      border: 0;
      border-radius: 80px;
      color: #333;
      font-size: 16px;
      line-height: 100%;
      height: 100%;
      margin-bottom: 0;
      padding: 0 70px 0 34px;
      position: relative;
      width: 100%;
    }
    button {
      background: transparent;
      border-color: transparent;
      border-radius: 0 80px 80px 0;
      color: #fff;
      cursor: pointer;
      height: 100%;
      margin-bottom: 0;
      padding: 0 10px;
      position: absolute;
      right: 0;
      text-align: center;
      top: 0;
      width: 60px;
      > img {
        display: block;
        margin: 0 auto;
        position: relative; 
      }
    }
  }
}

@media (max-width: 1280px) {
  .banner {
    min-height: 322px;
    padding: 155px 0 56px;
    h3 {
      margin-bottom: 24px;
    }
  }
}

@media (max-width: 1280px) {
  .banner {
    padding: 155px 0 58px;
    h3 {
      font-size: 1.375rem;
      line-height: 1.18;
    }
    &__search {
      width: 480px;
    }
  } 
}

@media (max-width: 767px) {
  .banner {
    min-height: unset;
    height: 320px;
    padding: 160px 0 36px;
    h3 {
      font-size: 1.25rem;
      line-height: 1.3;
    }
    &__search {
      height: 48px;
      width: 100%;
    }
  }
}