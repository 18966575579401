/*=============================================================================================
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Your Name
==============================================================================================*/
/* helper
----------------------------------------------------------------------------------------------*/
// @import "../milligram/Color";    // import your general color from milligram

/* general
----------------------------------------------------------------------------------------------*/
@import "../component/layout";
@import "../component/banner";
@import "../component/popup";

@import "../milligram/Color";
@import "../milligram/Variables";
@import "general";

/* misc styling
----------------------------------------------------------------------------------------------*/
body {
  background: #fbfbfb;
}
.misc-page {
  background: #fbfbfb;
  position: relative;
  &::before {
    background: url("/images/content/bg-search-result-02.png") no-repeat top
      272px right;
    background-size: cover;
    content: "";
    height: 766px;
    left: 0;
    position: absolute;
    top: 0;
    width: 265px;
    z-index: 0;
  }
  &::after {
    background: url("/images/content/bg-search-result-01.png") no-repeat top
      67px right;
    background-size: cover;
    content: "";
    height: 672px;
    position: absolute;
    right: 0;
    top: 0;
    width: 475px;
  }
  &__notfound {
    min-height: calc(100vh - 521px);
    margin: 0 auto -50px;
    padding: 228px 0 294px;
    position: relative;
    text-align: center;
    &::after {
      background: url("/images/content/bg-popup-ty.png") no-repeat bottom center;
      background-size: cover;
      bottom: 0;
      content: "";
      left: 0;
      height: 443px;
      position: absolute;
      right: 0;
      width: 100%;
    }
    &-text {
      margin: 0 auto;
      max-width: 560px;
      h2 {
        color: $color-primary;
        font-family: $font-syne;
        font-weight: 600;
        font-size: 80px;
        margin-bottom: 24px;
      }
      h3 {
        color: $color-primary;
        font-family: $font-syne;
        font-style: normal;
        font-weight: 600;
        margin-bottom: 24px;
        text-transform: uppercase;
      }
      p {
        margin-bottom: 24px;
      }
      .button.with-img {
        background: #fbfbfb;
        z-index: 2;
        &::before {
          background-color: #fbfbfb;
        }
      }
    }
  }
  &__searchresult {
    padding: 228px 0 88px;
    position: relative;
    z-index: 1;
    &-notfound {
      margin: 0 auto;
      max-width: 560px;
      padding: 40px 0 180px;
      text-align: center;
      > img {
        display: block;
        margin: 0 auto 24px;
      }
      h3 {
        color: $color-primary;
        font-family: $font-syne;
        font-weight: 600;
        margin-bottom: 24px;
      }
    }
    &-title {
      margin-bottom: 40px;
      h3 {
        color: $color-primary;
        margin-bottom: 16px;
        b {
          font-weight: 700;
        }
      }
      p {
        color: $color-primary;
        margin-bottom: 0;
      }
    }
    &-list {
      &--item {
        display: block;
        margin-bottom: 40px;
        &:hover h5 {
          color: $color-secondary;
        }
        h5 {
          color: #171717;
          margin-bottom: 12px;
        }
        p {
          color: #171717;
          font-size: 18px;
          line-height: 26px;
          margin-bottom: 0;
        }
      }
    }
  }
  &__thankyou {
    min-height: calc(100vh - 571px);
    max-height: 100vh;
    overflow: hidden;
    padding: 100px 0 220px;
    position: relative;
    text-align: center;
    z-index: 1;
    &.subscribe {
      height: 100vh;
      min-height: unset;
      padding: 0;
      .wrapper {
        padding: 100px 0 220px;
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100vh;
      }
      input {
        display: block;
        margin: 0 auto 24px;
        width: 340px;
      }
      .foot {
        margin-top: 90px;
        .social-media {
          display: flex;
          justify-content: center;
          margin: 0 0 24px;
          a {
            margin: 0 6px;
          }
        }
        .copyright {
          font-size: 12px;
          line-height: 17px;
          a:hover {
            color: $color-secondary;
          }
        }
      }
    }
    .logo img {
      display: block;
      height: 64px;
      margin: auto;
      width: auto;
    }
    &-title {
      margin: 140px auto 0;
      max-width: 560px;
      > img {
        display: block;
        margin: 0 auto 24px;
      }
      h3 {
        color: $color-primary;
        font-family: $font-syne;
        font-weight: 600;
        margin-bottom: 24px;
      }
      .button.with-img {
        background: #fbfbfb;
        &::before {
          background-color: #fbfbfb;
        }
      }
    }
  }
}

footer {
  background-color: transparent;
}

@media (max-width: 1400px) {
  .misc-page {
    &__notfound {
      padding-top: 180px;
    }
    &__searchresult {
      padding-top: 180px;
    }
    &__thankyou {
      max-height: unset;
      padding: 80px 0 120px;
      &.subscribe {
        height: unset;
        padding: 0;
        .wrapper {
          height: unset;
          padding: 80px 0 50px;
        }
      }
      &-title {
        margin-top: 100px;
      }
    }
  }
}

@media (max-width: 1280px) {
  .misc-page {
    &__notfound {
      align-items: center;
      display: flex;
      justify-content: center;
      padding: 230px 0 268px;
      &::after {
        background-size: contain;
      }
      &-text {
        h2 {
          font-size: 72px;
          line-height: 93px !important;
        }
      }
    }
    &__searchresult {
      padding-top: 180px;
    }
    &__thankyou {
      &.subscribe {
        .wrapper {
          height: 100vh;
        }
        .misc-page__thankyou-title {
          margin-top: 0;
        }
      }
      &-title {
        margin-top: 190px;
      }
    }
  }
}

@media (max-width: 1023px) {
  .misc-page {
    &::before {
      display: none;
    }
    // &::after {
    //   background: url("/images/content/bg-search-result-01-mobile.png") no-repeat center right;
    //   background-size: contain;
    //   height: 320px;
    //   top: 123px;
    //   width: 519px;
    // }
    &__notfound {
      padding: 200px 0 258px;
      &-text {
        h2 {
          font-size: 64px;
          line-height: 84px !important;
        }
      }
    }
    &__searchresult {
      padding-top: 168px;
      &-notfound {
        padding-bottom: 100px;
        > img {
          height: 72px;
          width: auto;
        }
      }
      &-list--item p {
        font-size: 1rem;
      }
    }
    &__thankyou {
      padding: 80px 0 190px;
      &.subscribe {
        .wrapper {
          height: 100vh;
        }
        .misc-page__thankyou-title {
          margin-top: 0;
        }
      }
      .logo img {
        height: 56px;
      }
      &-title {
        > img {
          height: 72px;
          width: auto;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .misc-page {
    // &::after {
    //   height: 240px;
    //   top: 82px;
    //   width: 389px;
    // }
    &__notfound {
      padding: 236px 0 251px;
      &-text {
        h2 {
          font-size: 64px;
        }
      }
    }
    &__searchresult {
      padding-top: 152px;
      &-notfound {
        padding-bottom: 50px;
        > img {
          height: 64px;
        }
      }
    }
    &__thankyou {
      padding: 40px 0 100px;
      &.subscribe {
        .wrapper {
          min-height: 600px;
          padding: 40px 0;
        }
        .foot {
          margin-top: 0;
        }
      }
      .logo img {
        height: 48px;
      }
      &-title {
        margin-top: 120px;
        > img {
          height: 64px;
        }
      }
    }
  }
}
