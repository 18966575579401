.popup-container {
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  overflow: auto;
  max-height: 100%;
  .overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 1;
    width: 100%;
    height: 100%;
  }
  .close-pop {
    position: absolute;
    width: 30px;
    height: 30px;
    top: 36px;
    right: 36px;
    cursor: pointer;
    transition: all ease-out 0.2s;
    &:hover {
      transition: all ease-out 0.2s;
      transform: scale(0.9);
    }
    &:before,
    &:after {
      content: "";
      height: 2px;
      width: 80%;
      background: #545454;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
  .popup-content {
    margin: auto;
    padding: 40px;
    border: none;
    width: 900px;
    box-sizing: border-box;
    min-height: 300px;
    position: relative;
    z-index: 2;
    border-radius: 10px;
    transform: scale(0.8);
    transition: all 0.3s ease-in-out;
    background: #fff;
    box-shadow: 0 1px 10px -2px rgba(0, 0, 0, 0.075);
  }
  &.show {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
    .popup-content {
      transform: scale(0.9);
    }
  }

  &.pop-general {
    .popup-content {
      padding: 90px 85px 60px;
      background: $color-initial url("/images/content/background-about.png")
        no-repeat top -60px left;
      background-size: 500px;
    }
  }
}

#popup-ty {
  &.popup-container .popup-content {
    border-radius: 0;
    padding-bottom: 300px;
    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 277px;
      background: url("/images/content/bg-popup-ty.png") no-repeat bottom center;
      background-size: contain;
    }
  }
}

/* responsive Desktop
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1366px) {
  .popup-container.pop-general .popup-content {
    padding: 60px 85px 40px;
  }
}

/* responsive tablet landscape
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1280px) {
  .popup-container {
    &.show {
      .popup-content {
        transform: scale(1);
      }
    }
  }
  #popup-ty.popup-container .popup-content {
    padding: 96px 0 254px;
  }
}

/* responsive tablet potrait
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1023px) {
  .popup-container.pop-general .popup-content {
    margin: auto 64px;
    width: auto;
  }
  #popup-ty.popup-container .popup-content {
    padding: 80px 100px 180px;
  }
}

/* responsive mobile
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 767px) {
  .popup-container .close-pop {
    right: 16px;
    top: 16px;
  }
  .popup-container.pop-general .popup-content {
    background-position: top 0 left -50px;
    background-size: 240px;
    margin: 0 16px;
  }
}

@media (max-height: 440px) {
  .popup-container.pop-general .popup-content {
    margin: auto;
  }
}
